import NunitoSansBold from './NunitoSans-Bold.woff2';
import NunitoSansBoldItalic from './NunitoSans-BoldItalic.woff2';
import NunitoSansItalic from './NunitoSans-Italic.woff2';
import NunitoSansRegular from './NunitoSans-Regular.woff2';

export const nunitoSans = {
    fontFamily: 'NunitoSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `local('NunitoSans'), local('NunitoSans-Regular'), url(${NunitoSansRegular}) format('woff2')`,
};

export const nunitoSansBold = {
    fontFamily: 'NunitoSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `local('NunitoSans'), local('NunitoSans-Bold'), url(${NunitoSansBold}) format('woff2')`,
};

export const nunitoSansItalic = {
    fontFamily: 'NunitoSans',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `local('NunitoSans'), local('NunitoSans-Italic'), url(${NunitoSansItalic}) format('woff2')`,
};

export const nunitoSansBoldItalic = {
    fontFamily: 'NunitoSans',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `local('NunitoSans'), local('NunitoSans-BoldItalic'), url(${NunitoSansBoldItalic}) format('woff2')`,
};

export default {
    fontFamily: "'NunitoSans', sans-serif",
    fontSize: 16,
    root: {},

    /* <Typography variant="xxx" */

    // Hero headline, Article title
    h1: {
        fontWeight: 400,
        fontSize: '2.8125rem',
        lineHeight: 1.2,
        fontFamily: 'NunitoSans',
    },
    // Snippet, Section, CardGrid title
    h2: {
        fontWeight: 700,
        fontSize: '1.875rem',
        lineHeight: 1.20,
        fontFamily: 'NunitoSans',
        letterSpacing: '0.01em',
    },
    // Snippet teaser, card title
    h3: {
        fontWeight: 700,
        fontSize: '1.5rem',
        lineHeight: 1.20,
        fontFamily: 'NunitoSans',
        letterSpacing: '0.01em',
    },
    // Profile name
    h4: {
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: 1.20,
        fontFamily: 'NunitoSans',
        letterSpacing: '0.01em',
    },
    // Hero copy
    h5: {
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: 1.5,
        fontFamily: 'NunitoSans',
        letterSpacing: '0.005em',
    },
    // Header brand text
    h6: {
        fontWeight: 400,
        fontSize: '0.9rem',
        lineHeight: 1.3,
        fontFamily: 'NunitoSans',
    },
    // Hero brand text, Article pullQuote
    subtitle1: {
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: 1.5,
        fontFamily: 'NunitoSans',
        letterSpacing: '0.005em',
    },
    // Article author
    subtitle2: {
        fontWeight: 700,
        fontSize: '1rem',
        lineHeight: 1.5,
        fontFamily: 'NunitoSans',
    },
    // General body text outside markdown
    body1: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.5,
        fontFamily: 'NunitoSans',
        letterSpacing: '0.005em',
    },

    button: {
        fontWeight: 400,
        fontSize: '0.9rem',
        fontFamily: 'NunitoSans',
        textTransform: 'none'
    },
};